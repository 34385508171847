.footer {
  background-color: #323232;
}

.footer,
.footer a {
  color: #F2F2F2 !important;
}

.footer .row {
  margin-left: auto;
  margin-right: auto;
  max-width: 938px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
}

.footer .col {
  display: block;
}


.footer .row :global(.nav-link) {
  text-align: start;
  min-height: 48px;
}


.footer .copyRightFooter {
  background-color: #222222 !important;
  margin: 0;
  margin-top: 5px;
  width: 100%;
  max-width: 100%;
  padding: 5px 105px;
}

.footer .copyRightFooter .row {
  max-width: 1500px;
}

@media (max-width: 575.9px) {
  .footer .copyRightFooter {
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    padding: 15px 0;
  }

  .footer :global(.col-xs-4) {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .footer .dealAndGoLogo {
    margin-bottom: 12px;
  }

  .footer .row > :global(.navbar-brand) {
    min-height: 48px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}
