.dealgo {
  font-family: "Varela Round";
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(242, 242, 242, 1) !important;
  padding-top: 0 !important;
}

.dealgoEmphasis2 {
  font-family: "Varela Round";
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(242, 143, 23, 1);
}
