.publishadbutton {
  background-color: rgba(242, 143, 23, 1);
  margin-right: 16px;
  border-radius: 3px;
  padding: 2px 6px;
  display: inline-block;
  align-items: flex-start;
  min-width: 142px;
}

.hrikmMsrp {
  width: 130px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(255, 255, 255, 1) !important;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
}

.publishadbutton .hrikmMsrp:hover {
  color: rgba(255, 255, 255, 1) !important;
}


@media (max-width: 767px) {

  .publishadbutton {
    border-radius: 50px;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 58px;
  }

  .hrikmMsrp {
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
  }
}
