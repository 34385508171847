.navigateLink .menuDivider {
  margin-left: 0.5em;
  margin-right: auto;
  padding-top: 1.3em;
  padding-bottom: 1.0em;
  margin-bottom: -0.8em;
}

@media screen and (max-width: 775px) {
  .navigateLink .menuDivider {
    padding-top: 0.0em;
    padding-bottom: 0.2em;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 766px) {
  .navigateLink .menuDivider {
    padding: 0;
    margin: 0;
    height: 1px;
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}
