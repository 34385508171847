.TextField,
.TextField.valid, {
  height: 56px;
  border-radius: 4px;
  //  border: 0.4px solid #BEBEBE;
  box-sizing: border-box;
  cursor: text;
  align-items: center;
  // padding: 14px;
  // margin: 0;
  background-color: white;

  font-size: 18px;
  line-height: 16px;
  text-align: start;
  top: 0;
}

.TextField :global(.MuiSelect-select:focus) {
  background-color: white !important;
  border-color: #BEBEBE !important;
}

.TextField label {
  color: #585858;
}

.TextField :global(.MuiFormLabel-filled) {
  top: 12px;
}

.TextField label[data-shrink="true"] {
  top: 12px;
  font-size: 13px;
  line-height: 20px;
  font-weight: normal;
  text-align: start;
  color: #BEBEBE !important;
  top: 12px;
}

.TextField fieldset legend {
  width: 0;
}

.TextField.readOnly input {
  cursor: default;
  color: #585858;
}

.TextField.readOnly :global(.Mui-focused),
.TextField.readOnly :global(.MuiSelect-select:focus),
.TextField :global(.MuiSelect-select:focus) {
  color: #BEBEBE;
  border-color: #BEBEBE;
}

.TextField.readOnly :global(.MuiInputBase-formControl:hover),
.TextField.readOnly :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline),
.TextField :global(fieldset) {
  border: 0.4px solid #BEBEBE !important;
}

.TextField.readOnly :global(.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline) {
  border-color: #BEBEBE !important;
  border-width: 0;
}

.TextField.noBorder,
.TextField.noBorder fieldset {
  border-color: white !important;
}

.TextField :global(.Mui-focused) fieldset {
  border-color: #BEBEBE !important;
}


.TextField:global(.error) fieldset {
  //  border-width: 1px;
  //  color: #c13515 !important;
  //  box-shadow: #c13515 0px 0px 0px 1px inset !important;
  //  background-color: #fff8f6 !important;
  //  border-color: #c13515 !important;

  border-color: #f44336 !important;
}

.clearTextIcon {
  color: #bebebe;
  fill: #bebebe;
  // position: absolute;
  // left: 2%;
  cursor: pointer;
  // z-index: 55;
}

.hidden {
  display: none;
}

.loadingField {
  color: #4c4c495e;
  height: 56px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
  display: block !important;
  max-width: 100% !important;
  font-size: 14px !important;
}
