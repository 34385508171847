.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  width: -moz-max-content;
  float: left;
  margin-top: -3px;
}


.row {

}

@media (max-width: 767px) {
  .nav {
    width: 100%;
    display: block;
    display: flex;
    flex-direction: column-reverse;
  }

  .row {
    margin-bottom: 20px;
  }

}
