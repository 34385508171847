ul.controlMenuContainer {
  background-color: #05BEC0;
  overflow-x: hidden;
  overflow-y: auto;
}

.MuiMenu-list ul.controlMenuContainer {
  padding-left: 27px;
  padding-right: 27px;
}

ul.controlMenuContainer li.MuiButtonBase-root {
  color: #FFFFFF;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  box-shadow: none;
}


ul.controlMenuContainer li.MuiButtonBase-root a {
  color: #FFFFFF;
  padding: 0;
  text-decoration: none;
  width: 100%;
  text-align: start;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

ul.controlMenuContainer li.MuiButtonBase-root svg {
  margin: 8px 17px 8px 19px;
}

ul.controlMenuContainer li.MuiButtonBase-root.active {
  background-color: #9EFEFE;
  border-radius: 42px;
}

ul.controlMenuContainer li.MuiButtonBase-root.controlcreateproduct.active {
  background-color: transparent;
  box-shadow: none;
  transition: none;
}

ul.controlMenuContainer li.MuiButtonBase-root.active svg path,
ul.controlMenuContainer li.MuiButtonBase-root.active a {
  color: #323232 !important;
  fill: #323232 !important;
}


ul.controlMenuContainer li.MuiButtonBase-root.controlcreateproduct span.innerText,
li.MuiButtonBase-root.controlcreateproduct a,
ul.controlMenuContainer li.MuiButtonBase-root.controlcreateproductvoucherstep1 span.innerText,
li.MuiButtonBase-root.controlcreateproductvoucherstep1 a {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

ul.controlMenuContainer li.MuiButtonBase-root.controlcreateproduct span.innerText,
li.MuiButtonBase-root.controlcreateproduct span.innerText,
ul.controlMenuContainer li.MuiButtonBase-root.controlcreateproductvoucherstep1 span.innerText,
li.MuiButtonBase-root.controlcreateproductvoucherstep1 span.innerText {
  color: #FFFFFF;
  background-color: #F28F17;
  margin-left: auto;
  margin-right: auto;
  border-radius: 52px;
  height: 40px;
  width: 75%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  padding-top: 5px;
  box-shadow: none;
}

li.MuiButtonBase-root.tourism span.innerText {
  color: #fff;
  background-color: #1be0a0;
  border-radius: 62px;
  padding: 5px 20px;
  font-size: 25px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

