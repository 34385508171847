.btn {
  margin-right: 8px;
  border-radius: 3px;
  display: flex;
  align-items: flex-start;
  border: 1px solid rgba(242, 242, 242, 1);
  min-width: 142px;
}

.tvprtch,
.tvprtch:hover {
  width: 104px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(242, 242, 242, 1);
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

}
/*
.tvprtch:global(.active:before) {
  background-color: rgb(255, 255, 255);
  border-radius: 1px;
  bottom: 3px;
  box-sizing: border-box;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -9px;
  position: absolute;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: ease;
  width: 18px;
}
*/
@media (max-width: 767px) {

  .btn {
    border-width: 2px;
    border-radius: 50px;
    width: 250px;
  }
  .tvprtch {
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
  }
}
