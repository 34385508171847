select.removeArrow, .removeArrow select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select.removeArrow::-ms-expand, .removeArrow select::-ms-expand {
  display: none;
}

.flag-select__btn:after {
  display: none;
}


* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

.signedIn .hideWhenSignedIn {
  display: none;
}

@media (min-width: 767px) {
  .signedIn .showWhenSignedIn767,
  .showWhen767 {
    display: none;
  }
}

@media (max-width: 767px) {
  .signedIn .hideWhenSignedIn767,
  .hideWhen767 {
    display: none;
  }
}
