header.navigationContainer {
  background-color: transparent;
  padding-left: 9%;
  padding-right: 9%;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border: 0px;
  box-shadow: none;
  max-height: 58px;
  z-index: 502 !important;
  overflow: hidden;
}

header.navigationContainer :global(.container-fluid) {
  padding-top: 20px;
  padding-bottom: 12px;
}

header.navigationContainer :global(ul.NavigationLinks) {
  margin-top: 12px;
}

header.navigationContainer :global(ul.NavigationLinks li:hover .nav-link:before),
header.navigationContainer :global(ul.NavigationLinks li.active .active:before) {
  background-color: rgb(255, 255, 255);
  border-radius: 1px;
  bottom: 13px;
  box-sizing: border-box;
  content: "";
  height: 2px;
  margin-left: -9px;
  position: absolute;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: ease;
}

header.navigationContainer :global(ul.NavigationLinks li:hover .nav-link:before) {
  left: 61%;
  width: 4px;
  height: 3px;
}

header.navigationContainer :global(ul.NavigationLinks li.active .active:before) {
  left: 50%;
  width: 18px;
}


header.navigationContainer :global(ul.NavigationLinks li) {
  border-radius: 52px;
}

header.navigationContainer :global(ul.NavigationLinks li a) {
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF !important;
  background-color: transparent !important;
  padding: 0;
}

header.navigationContainer:global(.top-nav-collapse) {
  background-color: #05BEC0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

header.navigationContainer :global(a.navbar-brand img) {
  width: 11vw;
  height: 77px;
}


header.navigationContainer :global(.burger .burger-lines),
header.navigationContainer :global(.burger .burger-lines:after),
header.navigationContainer :global(.burger .burger-lines:before) {
  background-color: #ffffff;
}

header.navigationContainer :global(.burgerContainer) {
  display: none;
}

header.navigationContainer :global(.burgerContainer .burgerText) {
  color: white;
  float: left;
  font-weight: 700;
  font-size: 26px;
  margin-top: -5px;
  position: relative;
  z-index: 1001;
}

header.navigationContainer :global(button.burger) {
  background-color: transparent;
  border: none;
  margin-left: 15px;
  outline: none !important;
  box-shadow: none !important;
  display: none;
  float: left;
}

header.navigationContainer .menuRowContainer {
  width: 100%;
}


header.navigationContainer .topNavigationLinks :global(a.nav-link) {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: -25px;
}

header.navigationContainer .mobilePublish {
  display: none;
}

@media (max-width: 1072px) {
  header.navigationContainer {
    padding-left: 0;
    padding-right: 0;
  }

  header.navigationContainer :global(.container-fluid),
  header.navigationContainer :global(.row) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1205px) {
  header.navigationContainer {
    padding: 0 !important;
  }

  header.navigationContainer :global(ul.navbar-nav) {
    padding: 0 !important;
  }
}

@media (max-width: 767.9px) {

  header.navigationContainer :global(ul.NavigationLinks li a.active) {
    color: #323232 !important;
  }

  header.navigationContainer.menuCollapseClosed {
    overflow: initial;
  }


  header.navigationContainer :global(ul.NavigationLinks .active:before) {
    display: none;
  }

  header.navigationContainer :global(ul.NavigationLinks li.navigateLink) {
    padding-top: 25px;
    margin-bottom: 14px !important;
  }

  header.navigationContainer :global(ul.NavigationLinks li.navigateLink a.nav-link) {
    line-height: 0;
  }

  header.navigationContainer :global(ul.NavigationLinks li.navigateLink.active) {

  }

  header.navigationContainer :global(.DealAndGoNavButtonsContainer) > :global(a.nav-link) {
    min-width: 200px;
    border-radius: 52px;
  }
  header.navigationContainer :global(.DealAndGoNavButtonsContainer) > :global(span) > :global(a.nav-link), {
    width: 100%;
  }
  header.navigationContainer :global(ul.NavigationLinks li.navigateLink.active),
  header.navigationContainer :global(.DealAndGoNavButtonsContainer) > :global(span) > :global(a.nav-link.active),
  header.navigationContainer :global(.DealAndGoNavButtonsContainer) > :global(a.nav-link.active) {
    color: #323232 !important;
    background-color: #9EFEFE;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    border-radius: 52px;
  }

  header.navigationContainer {
    max-height: 50px;
  }

  header.navigationContainer :global(.container-fluid) {
    padding-top: 8px;
    padding-bottom: 12px;

  }

  header.navigationContainer :global(.container-fluid .column) {
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  header.navigationContainer :global(.burgerContainer) {
    display: block;
  }

  header.navigationContainer :global(ul.navbar-nav .navbar.navbar-light .breadcrumb .nav-item .nav-link),
  header.navigationContainer:global(.navbar.navbar-light .navbar-nav .nav-item) {
    margin-bottom: 40px;
  }

  header.navigationContainer :global(ul.navbar-nav .navbar.navbar-light .breadcrumb .nav-item .nav-link),
  header.navigationContainer:global(.navbar.navbar-light .navbar-nav .nav-item .nav-link) {
    font-size: 25px;
    height: 40px;
  }

  header.navigationContainer :global(ul.navbar-nav) {
    margin-top: 80px;
  }

  header.navigationContainer :global(a.navbar-brand) {
    margin: 0 auto 0;
    position: relative;
    margin-top: -21px;
    z-index: 1001;
  }

  header.navigationContainer :global(.collapsing),
  header.navigationContainer :global(.collapse.show) {
    transition: none;
    background-color: #05BEC0;
    z-index: 1000;
    position: absolute;
    width: 100vw;
    height: 115vh;
    left: 0;
    margin-top: -20px;
  }

  header.navigationContainer :global(a.navbar-brand img) {
    width: 24vw;
  }

  header.navigationContainer :global(button.burger) {
    display: inline-block;
    z-index: 1001;
  }

  header.navigationContainer:global(.signedIn) .menuRowContainer {
    flex-direction: column-reverse;
  }

  header.navigationContainer .mobilePublish {
    display: block;
    width: 123px;
    height: 24px;
    position: absolute;
    left: 69px;
    top: 5px;
  }

  header.navigationContainer .mobilePublish > :global(.nav-link) {
    font-weight: 500;
    font-size: 15px;
    line-height: 4px;
  }


}
