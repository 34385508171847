.SearchSelectorLabel {
  height: 85px;
}

.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control) {
  height: 56px;
  border-radius: 4px;
  border: 0.4px solid #BEBEBE;
  box-sizing: border-box;
  cursor: text;
  align-items: center;
  padding: 14px;
  margin: 0;
  top: -25px;
  background-color: transparent;
  background-color: white;

  font-size: 18px;
  line-height: 16px;
  text-align: start;
  color: #BEBEBE;
}

.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control:hover) {
  background-color: transparent;
  background-color: white;
}


.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control.Mui-error) {
  box-shadow: rgb(193 53 21) 0px 0px 0px 1px inset !important;
  background-color: rgb(255, 248, 246) !important;
  border-color: rgb(193, 53, 21) !important;
  margin-top: -4px;
  top: -21px;
}

.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control.Mui-error.SearchSelector__control--is-focused) {
  // margin-top: 0px;
}


.SearchSelectorLabel:global( .Mui-error) label {
  color: rgb(193, 53, 21) !important;
  // top: -7px !important;
}


.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control .SearchSelector__indicators) {
  flex-direction: row-reverse;
  left: 0;
  right: 25px;
  top: 0;
}


.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control .SearchSelector__indicators .SearchSelector__clear-indicator) {
  color: #BEBEBE;
}

.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control .SearchSelector__single-value) {
  padding: 0;
  max-width: 100%;
  color: #585858;
}


.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control .MuiInputBase-input.MuiOutlinedInput-input) {

}

.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control.Mui-error) {
  border-width: 0;
  border-color: rgb(193, 53, 21) !important;
}

.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__control.valid) {
}

.SearchSelectorLabel :global(.error fieldset) {
  border-width: 2px;
  border-color: rgb(193, 53, 21) !important;
}

:global(.valid fieldset) {
  /*
  border-color: #00fda2;
  border-width: 2px;
   */
}

.SearchSelectorLabel:global(.MuiFormControl-root .SearchSelector__menu) {
  z-index: 801;
  border-width: 1px;
  border-style: solid;
  margin-top: -30px;
  margin-bottom: 0;
}

.SearchSelectorLabel label {
  width: fit-content !important;
  pointer-events: none;
  z-index: 55;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: right;
  color: #585858 !important;
}

.SearchSelectorLabel label[data-shrink="false"] {
  right: 13px;
  top: -4px;
}

.SearchSelectorLabel label[data-shrink="true"] {
  z-index: 100;
  top: 6px !important;
  right: 17px;
  font-size: 13px;
  font-weight: normal;
  text-align: right;
  color: #BEBEBE !important;
  // line-height: 20px;
}

.SearchSelectErrorsContainer {
  position: absolute;
  margin-top: -28px !important;
  margin-right: 10px;
}


.SearchSelectorLabel :global(.SearchSelector__value-container) {
  margin: 0;
  padding: 0;
}

.SearchSelectorLabel :global(.SearchSelector__value-container--has-value) {
  padding-top: 11px;
}


.SearchSelectorLabel :global(.SearchSelector__indicator-separator) {
  display: none !important;
}

.noBorder :global(.SearchSelector__control) {
  border-color: white !important;
}

.SearchSelectorLabel.notSearchable:global(.MuiFormControl-root .SearchSelector__control) {
  cursor: initial;
}

.SearchSelectorLabel.multi :global(.SearchSelector__indicators) {
  display: none;
}
