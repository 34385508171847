ul.loggedInAccountContainer {
  margin-top: -9px;
}

ul.loggedInAccountContainer :global(.nav-item) {
  margin-top: 5px;
}


ul.loggedInAccountContainer :global(.nav-item) .myAccountMenu {
  margin-top: -3px;
}

ul.loggedInAccountContainer .myAccountMenu {
  color: #F2F2F2;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  background-image: url(/assets/img/whiteCaret.svg);
  background-repeat: no-repeat;
  background-position: 149px 22px;
  padding-right: 18px;
}

ul.loggedInAccountContainer .myAccountMenu:global(.menuOpen) {


}

ul.loggedInAccountContainer .myAccountMenu :global(.MuiAvatar-root) {
  margin-right: 10px;
}

ul.loggedInAccountContainer {
  padding-right: 0;
}

ul.loggedInAccountContainer button.myAccountMenu {
  outline: none;
}

ul.mobileLoggedInAccountContainer {
  display: none;
  height: 80vh;
  overflow: auto;
}

.loggedInAccountMenuContainer .controlMenuContainer {
  min-width: 275px;
}

.mobileLoggedInAccountContainer .homeLink {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .loggedInAccountContainer {
    display: none;
  }

  ul.mobileLoggedInAccountContainer :global(li.nav-item) {
    margin-bottom: 5px !important;
  }

  .mobileLoggedInAccountContainer :global(a.nav-link) {
    color: white !important;
    background-color: transparent !important;
  }

  ul.mobileLoggedInAccountContainer {
    display: block;
    height: 85vh;
    overflow: scroll;
  }

  .mobileLoggedInAccountContainer :global(.MuiAvatar-root) {
    display: inline-block;
  }

  .mobileLoggedInAccountContainer :global(.fullName) {
    color: white;
    font-size: 22px;
    display: inline-block;
    padding-bottom: 0px;
    position: relative;
    top: -10px;
    left: -14px;
  }

  .mobileLoggedInAccountContainer :global(li.controlcreateproduct) {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;
  }

  .mobileLoggedInAccountContainer :global(li.controlcreateproduct) a {
    height: 58px !important;
    padding-top: 14px !important;
  }

  .mobileLoggedInAccountContainer :global(li.controlcreateproduct) a span {
    font-size: 25px;
    padding-left: 50px !important;
    padding-right: 50px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .mobileLoggedInAccountContainer :global(li.logout) {
    text-align: center !important;
    width: 100% !important;;
    display: block !important;;
    font-size: 28px !important;;
    background-position: 65.33% 9px !important;
  }
}
