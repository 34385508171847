.hsink {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(242, 242, 242, 1);
  text-align: center;
  width: fit-content;

}

.hsink:global(.nav-link:hover:before),
.hsink:global(.active:before) {
  background-color: rgb(255, 255, 255);
  border-radius: 1px;
  bottom: 0px;
  box-sizing: border-box;
  content: "";
  height: 2px;
  margin-left: -9px;
  position: absolute;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: ease;
}

.hsink:global(:hover:before) {
  left: 61%;
  width: 4px;
  height: 3px;
}

.hsink:global(.active) {
  cursor: default !important;
  pointer-events: none;
}

.hsink:global(.active:before) {
  left: 50%;
  height: 1px !important;
  width: 18px !important;
}


.hsink:hover, .tvprtch:hover {
  color: rgba(242, 242, 242, 1) !important;
}

@media (max-width: 767px) {
  .hsink {
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
  }

}
