html, body {
  min-height: 100%;
  height: 100%;
  display: block;
  font-family: rubik !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html[dir='rtl'] body, body[dir='rtl'] {
  text-align: right !important;
}

body, div#root {
  margin: 0;
  min-height: 100%;
  background-color: #F2F2F2;
  display: block;
  padding: 0px;
}

body div#root > main {
  min-height: 83.2vh;
}

.directionRtl {
  direction: rtl;
}

.directionLtr {
  direction: ltr;
}

/* Disable chrome autofill background color of inputs*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.disableWordBreak {
  word-break: unset !important;
  word-wrap: unset !important;
  overflow-wrap: unset !important;
  -webkit-hyphens: unset !important;
  -moz-hyphens: unset !important;
  -ms-hyphens: unset !important;
  hyphens: unset !important;
  white-space: nowrap;
}


@import "~react-image-gallery/styles/css/image-gallery.css";
